// auto import 対象外
// description生成まわり

// meta descriptionオブジェクト形式にする
export function getDescription(description: string) {
  return {
    name: "description",
    content: description,
  };
}

// ページ番号テキスト
export function getOffsetText(off: number) {
  return off > 0 ? `${off + 1}ページ` : "";
}
